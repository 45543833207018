.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 80vh;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    background: #fcfcfc;
}


.dndflow .dndnode {
    height: 20px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }
    .dndflow aside {
        width: 15%;
    }
}

/*.condition-node {*/
/*    border: 1px solid #eee;*/
/*    border-radius: 5px;*/
/*    background: #b72727;*/
/*    width: 90px;*/
/*    height: 90px;*/
/*    transform: rotate(45deg);*/
/*    white-space: pre-line;*/
/*}*/
/*.condition-node-inner{*/
/*    transform: translate(0px,0px) rotate(-45deg);*/
/*    white-space: pre-line;*/
/*}*/

.condition-node {
    width: 110px;
    height: 110px;

}
.condition-node-inner{
    transform: rotate(45deg);
    border: 1px solid #4d4d4d;
    border-radius: 5px;
    background: #fcfcfc;
    white-space: pre-line;
    width: 85px;
    height: 85px;
}

.condition-node-inner-text{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    transform: translate(0px,0px) rotate(-45deg);
    white-space: pre-line;
}

.stylist-node {
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 5px;
    background: #fcf3de;
}


.retriever-node {
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 5px;
    background: #f1f1ff;
    width: 150px;
    height: 55px;
}

.retriever-node-puzzle{
    /*border: 1px solid #eee;*/
    padding: 8px;
    border-radius: 5px;
    /*background: #f1f1ff;*/
    width: 140px;
    height: 50px;
    white-space: pre-line;
}
#bouncer {
    background: #cadcff;
    border: 0.3px solid #1265ff;
    border-bottom: 5px solid #1265ff;
}
#stylist {
    background: #d1c8ff;
    border: 0.3px solid #5529ff;
    border-bottom: 5px solid #5529ff;

}
#retriever {
    background: #eaffda;
    border: 0.3px solid #b0ff60;
    border-bottom: 5px solid #b0ff60;
}
#breaker {
    background: #ffe4dc;
    border: 0.3px solid #fd5c25;
    border-bottom: 5px solid #fd5c25;
}

.action-node-puzzle{
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 5px;
    background: #dadada;
    width: 140px;
    white-space: pre-line;
}

.info-node-puzzle{
    border: 1px dashed #4d4d4d;
    padding: 8px;
    border-radius: 0px;
    background: #fcfcfc;
    width: 140px;
    white-space: pre-line;
}


.gate-node{
    border: 1px solid #4d4d4d;
    /*padding: 8px;*/
    border-radius: 5px;
    background: #fcfcfc;
    width: 70px;
    height: 70px;
    transform: rotate(45deg);
}

.gate-node-inner{
    /*padding: 20px;*/
    transform: translate(0px,0px) rotate(-45deg)
}

.text-updater-node {
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 5px;
    background: #fcfcfc;
    width: 150px;
    height: 55px;
}

.text-updater-node-puzzle {
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 5px;
    background: #fcfcfc;
    width: 95px;
    height: 35px;
}

.channel-node{
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 50%;
    background: #fcfcfc;
    width: 70px;
    height: 70px;
    white-space: pre-line;
}

.channel-node-puzzle{
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 50%;
    background: #d2ffc4;
    width: 70px;
    height: 70px;
    white-space: pre-line;
}


.response-node{
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 50%;
    background: #d2ffc4;
    width: 70px;
    height: 70px;
    white-space: pre-line;
}
.responze-node-puzzle{
    border: 1px solid #4d4d4d;
    padding: 8px;
    border-radius: 50%;
    background: #d2ffc4;
    width: 70px;
    height: 70px;
    white-space: pre-line;
}

textarea{
    font-size: 7pt;
}

.text-updater-node label {
    display: block;
    color: #777;
    font-size: 12px;
}

.updatenode__controls {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 4;
    width: 40%;
}

.updatenode__controls__mobile {
    position: relative;
    left: -16px;
    top: -16px;
    width: calc(100% + 32px);
}

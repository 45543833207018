.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border: 2px #eaeafa dashed;
    border-radius: 10px;
    width: 100%;
    /*height: 20vh;*/
    margin: auto;
}

aside ul{
    padding: 0px;
    width: 100%;
    margin: auto;
}


.arrow {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
}

.arrow > div {
    align-items: center;
    display: flex;
}

.arrow.is-open {
    transform: rotate(90deg);
}

.filetype {
    display: flex;
}

.label {
    padding-inline-start: 8px;
}


.dragging {
    opacity: 0.5;
}

.expandIconWrapper {
    align-items: center;
    font-size: 0;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
    transform: rotate(90deg);
}


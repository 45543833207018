.storyRoot {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
}


.treeRoot {
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 10px;
}

.dropTarget {
    box-shadow: 0 0 0 1px #7F39FB inset;
}

ul {
    list-style: none;
}

.rootGrid {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}


.draggingSource {
    opacity: 0.3;
}

.item {
    align-items: center;
    color: #fff;
    display: inline-grid;
    font-size: 14px;
    gap: 8px;
    grid-template-columns: auto auto;
}

.icon,
.label {
    align-items: center;
    display: flex;
}

.badge {
    border: solid 2px #fff;
}

/* Импорт шрифта Google font - Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

:root {
  --main-color: #0AA89A;
  --message-color: #0AA89A;
  --button_toggler-color: #0AA89A;
  --button_send-color: #0AA89A;
  --background-color: #FFFFFF;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

#widget-body {
    display: none;
}

.chatbot-toggler {
    position: fixed;
    bottom: 30px;
    right: 35px;
    outline: none;
    border: none;
    height: 50px;
    width: 50px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--button_toggler-color);
    transition: all 0.3s ease;
}

.chatbot-toggler:active {
    transform: scale(1.15) !important;
}

.chatbot-toggler:hover span {
    transform: scale(1.2);
}

#widget-body.show-chatbot .chatbot-toggler {
    transform: rotate(90deg);
}

.chatbot-toggler span {
    color: #fff;
    position: absolute;
    transition: all 0.3s ease;
}

.chatbot-toggler span:last-child,
#widget-body.show-chatbot .chatbot-toggler span:first-child {
    opacity: 0;
}

#widget-body.show-chatbot .chatbot-toggler span:last-child {
    opacity: 1;
}

.chatbot {
    position: fixed;
    right: 35px;
    bottom: 90px;
    width: 420px;
    background: var(--background-color);
    border-radius: 15px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.5);
    transform-origin: bottom right;
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
    0 32px 64px -48px rgba(0, 0, 0, 0.5);
    transition: all 0.1s ease;
}

#widget-body.show-chatbot .chatbot {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}

.chatbot header {
    padding: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: var(--main-color);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatbot header .text {
    display: flex;
    flex-direction: column;
}

.chatbot header .text .title {
    font-weight: 600;
    font-size: 1.1rem;
}

.chatbot header .text .worktime {
    padding-top: 2px;
    font-size: 0.75rem;
}

.chatbot header .logo {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    overflow: hidden;
}

.chatbot header .logo img {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.chatbot header .close-btn {
    position: absolute;
    right: 15px;
    top: 50%;
    display: none;
    cursor: pointer;
    transform: translateY(-50%);
}

.chatbot .chatbox {
    overflow-y: auto;
    height: 510px;
    padding: 30px 20px 100px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar {
    width: 6px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-track {
    background: var(--background-color);
    border-radius: 25px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
}

.chatbox .chat {
    display: flex;
    list-style: none;
}

.chatbox .outgoing {
    margin: 20px 0;
    justify-content: flex-end;
}

.chatbox .incoming span {
    width: 32px;
    height: 32px;
    color: #fff;
    cursor: default;
    text-align: center;
    line-height: 32px;
    align-self: flex-end;
    background: var(--message-color);
    border-radius: 4px;
    margin: 0 10px 7px 0;
}

.chatbox .chat p {
    white-space: pre-wrap;
    padding: 12px 16px;
    border-radius: 10px 10px 0 10px;
    max-width: 75%;
    color: #fff;
    font-size: 0.95rem;
    background: var(--message-color);
}

.chatbox .incoming p {
    border-radius: 10px 10px 10px 0;
}

.chatbox .chat p.error {
    color: #721c24;
    background: #f8d7da;
}

.chatbox .incoming p {
    color: #000;
    background: #f2f2f2;
}

.chatbot .chat-input {
    display: flex;
    gap: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--background-color);
    padding: 3px 20px;
    box-shadow: 0 -3px 5px -2px rgba(0,0,0,0.1);}

.chat-input textarea {
    height: 55px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    max-height: 180px;
    background: var(--background-color);
    padding: 15px 15px 15px 0;
    font-size: 0.95rem;
}

.chat-input span {
    align-self: flex-end;
    color: var(--button_send-color);
    cursor: pointer;
    height: 55px;
    display: flex;
    align-items: center;
    visibility: hidden;
    font-size: 1.35rem;
}

.chat-input textarea:valid ~ span {
    visibility: visible;
}

@media (max-width: 490px) {
    .chatbot-toggler {
        right: 20px;
        bottom: 20px;
    }

    .chatbot {
        right: 0;
        bottom: 0;
        height: 100%;
        border-radius: 0;
        width: 100%;
        padding-top: 64px;
    }

    .chatbot .chatbox {
        height: 90%;
        padding: 25px 15px 100px;
    }

    .chatbot .chat-input {
        padding: 5px 15px;
    }

    .chatbot header {
        padding: 15px 54px 15px 15px;
    }

    .chatbot header .close-btn {
        display: block;
    }
}